import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import Loader from './Loader'

export default function Table({
    loading = false,
    // totalCount,
    // pageSize = 15,
    showEmptyMessage = true,
    items,
    columns,
    renderItem,
    // onPageChange,
    // activePage,
    className,
    border = false,
}) {
    // const params = useQueryParams()

    if (loading) {
        return (
            <div className={css(styles.space)}>
                <Loader large center />
            </div>
        )
    }


    if (isEmpty(items) && showEmptyMessage) {
        return (
            <div className={cn('is-size-3 has-text-centered has-text-grey', css(styles.space))}>
                Пусто
            </div>
        )
    }

    return (
        <div>
            <table className={cn('table is-striped is-fullwidth', border ? '' : 'is-bordered', className)}>
                <tbody>
                    {columns ? (
                        <tr>
                            {Object.entries(columns).map(([key, value]) => (
                                <th key={key}>{value}</th>
                            ))}
                        </tr>
                    ) : null}


                    {items.map(renderItem)}
                </tbody>
            </table>

            {/* <Pagination
                currentPage={params.page || activePage}
                totalCount={(totalCount)}
                pageSize={pageSize}
                onPageChange={(page) => {
                    if (typeof onPageChange === 'function') onPageChange(page)
                }}
            /> */}
        </div>
    )
}


const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
})
