import React from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn'
import { Form, Formik } from 'formik'
import { useLoad, useSortTableData } from '../hooks/request'
import { BALANCE_DETAIL, BALANCE_ITEM_LIST, FINANCE_LIST, USERS_LIST } from '../urls'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import Layout from '../components/Layout'
import UserSelect from '../components/componentsFinance/common/UserSelect'
import { getMonth } from '../utils/date'
import { checkPermission as cp } from '../utils/auth'
import { useQueryParams } from '../hooks/queryString'
import BalanceItemItem from '../components/BalanceItemItem'


export default function UploadBalance() {
    const { balanceId } = useParams()
    const params = useQueryParams()
    const finances = useLoad({
        url: FINANCE_LIST,
        params: { balance: balanceId, ...params },
    })

    const reportDetail = useLoad({ url: BALANCE_DETAIL.replace('{id}', balanceId) })
    const reportItems = useLoad({ url: BALANCE_ITEM_LIST.replace('{balanceId}', balanceId) })
    const { items, requestSort, sortConfig } = useSortTableData(finances.response ? finances.response.results : [])
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return
        }
        return (
            sortConfig.key === name ? sortConfig.direction : undefined
        )
    }
    const users = useLoad({ url: USERS_LIST })
    const userItem = users.response ? users.response.results : []
    const userOptions = Object.entries(userItem).map(([, {
        id,
        nickname,
    }]) => ({
        value: nickname,
        name: id,

    }))
    return (
        <Layout>
            <div className="">
                <div className="level">
                    <div className="level-left">
                        <span className="title is-capitalized">
                            {reportDetail.response ? getMonth(reportDetail.response.date) : ''}
                        </span>
                    </div>
                    <div className="level-right">
                        {cp('staff.delete_employee') ? (
                            <Formik initialValues={{ user: '' }}>
                                <Form className="mr-3">
                                    <UserSelect
                                        emptyMessage="Ходимлар"
                                        empty
                                        options={userOptions}
                                        name="user"
                                    />
                                </Form>
                            </Formik>

                        ) : null}
                        <div className="is-pulled-right is-success is-outlined mr-3">
                            <ExcelFile
                                element={(
                                    <Button
                                        icon="ion-md-download"
                                        text="Excel"
                                        className="is-pulled-right is-success is-outlined mr-3" />
                                )}
                                filename={reportDetail.response ? getMonth(reportDetail.response.date) : ''}>
                                <ExcelSheet name="Excel" data={finances.response ? finances.response.results : []}>
                                    <ExcelColumn label="Корхона номи" value="name" />
                                    <ExcelColumn label="Юридик стир" value="stir" />
                                    <ExcelColumn label="Жисмоний стир" value="directorStir" />
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                        <Button
                            icon="ion-md-search"
                            text="Корхона номи"
                            className={cn(getClassNamesFor('name'),
                                'is-pulled-right is-success is-outlined mr-3')}
                            onClick={() => requestSort('name')} />
                        <Button
                            icon="ion-md-search"
                            text="Юридик стир"
                            onClick={() => requestSort('stir')}
                            className={cn(getClassNamesFor('stir'),
                                'is-pulled-right is-success is-outlined mr-3')} />
                        <Button
                            icon="ion-md-search"
                            text="Жисмоний стир"
                            onClick={() => requestSort('directorStir')}
                            className={cn(getClassNamesFor('directorStir'),
                                'is-pulled-right is-success is-outlined')} />
                    </div>
                </div>

                <Table
                    loading={finances.loading}
                    items={items}
                    columns={{
                        id: '№',
                        name: 'Мижоз корхона номи',
                        stir: 'Юридик стир',
                        directorStir: 'Жисмоний стир',
                        uploadRotation: 'Молиявий Баланс',
                        commentRotation: 'Изоҳ',
                        tax: 'Солиқ',
                        inps: 'ИНПС',
                        ad: '',
                    }}
                    renderItem={(item, index) => (
                        <BalanceItemItem
                            index={index}
                            key={item.id}
                            onUpdate={finances.request}
                            onDelete={finances.request}
                            request={reportItems.request}
                            reportItems={reportItems.response ? reportItems.response.results : []}
                            rotationId={balanceId}
                            finance={item} />
                    )} />

            </div>
        </Layout>
    )
}
