import React, { useState } from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_FINANCE_LIST } from '../urls'
import ManthlyFinanceForm from './ManthlyFinanceForm'
import ServerError from './common/ServerError'

export default function ManthlyFinanceCreate({ onCancel, onSuccess }) {
    const financeCreate = usePostRequest({ url: MANTHLY_FINANCE_LIST })
    const [serverError, setServerError] = useState()

    async function onSubmit(data) {
        const { success, error } = await financeCreate.request({ data })
        if (error) {
            setServerError((error))
        }
        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <ServerError error={serverError} />
            <ManthlyFinanceForm onSubmit={onSubmit} onCancel={onCancel} loading={financeCreate.loading} />
        </div>
    )
}
