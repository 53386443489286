import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import Button from './componentsFinance/common/Button'
import { useModal } from '../hooks/modal'
import { domain } from '../utils/request'
import RotationItemCreate from './RotationItemCreate'
import RotationItemUpdate from './RotationItemUpdate'
import { ROTATION_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'

export default function RotationItemItem({ reportItems, finance, rotationId, index, request }) {
    const reportItem = reportItems.filter((item) => item.finance === finance.id)[0]

    const [showModal, hideModal] = useModal(
        <RotationItemCreate
            onSuccess={() => {
                hideModal()
                request()
            }}
            rotationId={rotationId}
            finance={finance}
            onCancel={() => hideModal()} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <RotationItemUpdate
            onSuccess={() => {
                hideUpdateModal()
                request()
            }}
            reportItem={reportItem}
            finance={finance}
            rotationId={rotationId}
            onCancel={() => hideModal()} />,
    )

    function onSubmit() {
        if (reportItem) {
            showUpdateModal()
            return
        }
        showModal()
    }

    const [taxStatus, setTaxStatus] = useState(reportItem && reportItem.tax ? reportItem.tax : false);
    const url = ROTATION_ITEM_DETAIL.replace('{id}', reportItem?.id);
    const rotationItemUpdate = usePutRequest({ url });

    useEffect(() => {
        setTaxStatus(reportItem && reportItem.tax ? reportItem.tax : false);
    }, [reportItem]);

    async function onUpdate(status) {
        const { success } = await rotationItemUpdate.request({ data: { tax: status } });
        if (success) {
            request();
        }
    }

    function handleTaxStatusChange(event) {
        const newTaxStatus = event.target.checked;
        setTaxStatus(newTaxStatus);
        onUpdate(newTaxStatus);
    }

    return (
        <tr className="pl-3 pr-3">
            <td className="has-text-centered">
                {index + 1}
            </td>


            <td className={cn(reportItem ? 'has-text-centered has-text-black' : 'has-text-centered has-text-danger')}>
                {finance.name}
            </td>

            <td className="has-text-centered">
                {finance.stir}
            </td>

            <td className="has-text-centered">
                {finance.directorStir}
            </td>

            {reportItem ? (
                <>
                    <td className="has-text-centered">
                        <a className="has-text-black tag is-info is-light" href={domain + reportItem.rotationPdf}>
                            Скачать
                        </a>
                    </td>

                    <td className="has-text-centered">
                        {reportItem.rotationComment}
                    </td>

                </>
            ) : (
                <>
                    <td className="has-text-centered">
                        Пусто
                    </td>
                    <td className="has-text-centered">
                        Пусто
                    </td>
                </>
            )}
            <td className="has-text-centered">
                                <input style={{width: 22, height:22}} type="checkbox" checked={taxStatus} onChange={handleTaxStatusChange}/>

            </td>

            {/* <td className="has-text-centered"> */}
            {/*    <input type="checkbox" checked={reportItem ? reportItem.inps : false} /> */}
            {/* </td> */}

            <td>
                <Button
                    onClick={onSubmit}
                    className={reportItem ? 'button is-success is-light' : 'button is-danger is-light'}
                    icon={reportItem ? 'icon ion-md-create' : 'icon ion-md-add'}
                    text={reportItem ? 'Обновить' : 'Добавить'}
                />
            </td>
        </tr>
    )
}
