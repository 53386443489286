import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { MANTHLY_BALANCE_LIST, USERS_LIST } from '../urls'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import UserSelect from '../components/componentsFinance/common/UserSelect'
import ManthlyBalanceCreate from '../components/ManthlyBalanceCreate'
import ManthlyBalanceItem from '../components/ManthlyBalanceItem'
import ManthlyTabs from '../components/ManthlyTabs'

export default function ManthlyBalance() {
    const [page, setPage] = useState(1)
    const report = useLoad({ url: MANTHLY_BALANCE_LIST })
    const reportItem = report.response ? report.response.results : []

    const [showModal, hideModal] = useModal(
        <ManthlyBalanceCreate
            onSuccess={() => {
                hideModal()
                report.request()
            }}
            onCancel={() => hideModal()} />,
    )

    const users = useLoad({ url: USERS_LIST })
    const userItem = users.response ? users.response.results : []
    const userOptions = Object.entries(userItem).map(([, {
        id,
        nickname,
    }]) => ({
        value: nickname,
        name: id,
    }))
    return (
        <Layout>
            <ManthlyTabs />
            <div className="columns mt-3 mr-3">
                <div className="column is-capitalized ml-4">
                    <span className="is-size-4">Молиявий Баланс:</span>
                </div>
                <div className="column mt-3">
                    <div className="columns is-pulled-right">
                        <Formik initialValues={{ user: '' }}>
                            <Form className="mr-3">
                                <UserSelect
                                    emptyMessage="Ходимлар"
                                    empty
                                    options={userOptions}
                                    name="user"
                                />
                            </Form>
                        </Formik>
                        <Button
                            icon="ion-md-calendar"
                            text="Янги ой қўшиш"
                            onClick={showModal}
                            className="button is-success is-outlined"
                        />
                    </div>
                </div>
            </div>

            <Table
                loading={report.loading}
                items={reportItem}
                columns={{
                    id: '№',
                    name: 'Ой номи',
                    stir: 'Мижозлар',
                    directorStir: 'Ҳисоботлар жўнатилиши',
                    ssuploadRotation: 'Солиқлар тўланиши',
                    xdirectorStir: 'ИНПС реестр жўнатолиши',
                    ad: '',
                    uploadRotation: '',
                    assd: '',
                }}
                totalCount={report.response ? report.response.count : 0}
                pageSize={10}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item, index) => (
                    <ManthlyBalanceItem
                        reportItem={item}
                        index={index}
                        key={item.id}
                        onUpdate={report.request}
                        onDelete={report.request}
                        request={report.request}
                        reportItems={reportItem.response ? reportItem.response.results : []}
                        finance={item} />
                )} />
        </Layout>
    )
}
