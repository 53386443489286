import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useState } from 'react'
import { useQueryParams } from '../hooks/queryString'
import { useLoad } from '../hooks/request'
import TaskCreate from '../components/TaskCreate'
import { PermissionDenied } from '../components/PermissionDenied'
import { useModal } from '../hooks/modal'
import Button from '../components/common/Button'
import Table from '../components/common/Table'
import TaskItem from '../components/TaskItem'
import { TASK_LIST } from '../urls'
import { checkPermission as cp } from '../utils/auth'
import Layout from '../components/Layout'
import TaskFilter from '../components/TaskFilter'

export default function Task() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const tasks = useLoad({ url: TASK_LIST, params: { ...params, page } }, [page])

    const [showCreateModal, hideCreateModal] = useModal(
        <TaskCreate onSuccess={() => {
            hideCreateModal()
            tasks.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('task.view_task')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <div className={cn('columns', css(styles.heading))}>
                <div className="column">
                    <span className="is-size-4">Задания</span>&nbsp; &nbsp;
                </div>

                <div className="column is-narrow">
                    {cp('task.add_task') ? (
                        <Button
                            onClick={showCreateModal}
                            text="Добавить"
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined" />
                    ) : null}
                </div>
            </div>

            <TaskFilter /><br />

            { tasks.response ? (
                <Table
                    loading={tasks.loading}
                    totalCount={tasks.response ? tasks.response.count : 0}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    columns={{
                        title: 'Sarlavha',
                        assigned: 'Mas\'ul xodim',
                        dueTime: 'Muddati',
                        status: 'Holat',
                        actions: '',
                    }}
                    items={tasks.response ? tasks.response.results : []}
                    renderItem={(item) => (
                        <TaskItem onDelete={tasks.request} onUpdate={tasks.request} key={item.id} item={item} />
                    )} />
            ) : null}

            <br />
        </Layout>
    )
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 0,
    },
})
